import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomSmallLoader from "./components/CustomSmallLoader";

// Use React.lazy() to dynamically import components
const Homepage = lazy(() => import("./pages/Home"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./pages/RefundPolicy"));
const TermsAndConditions = lazy(() => import("./pages/TermsandConditions"));
const ShippingPolicy = lazy(() => import("./pages/ShippingPolicy"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const About = lazy(() => import("./pages/About"));
const Feedback = lazy(() => import("./pages/Feedback"));
const RiggleService = lazy(() => import("./pages/RiggleService"));
const Product = lazy(() => import("./pages/Product"));
const Help = lazy(() => import("./pages/Help"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Suspense fallback={<CustomSmallLoader/>}><Homepage /></Suspense>} />
        <Route path="/about" element={<Suspense fallback={<CustomSmallLoader/>}><About /></Suspense>} />
        <Route path="/product" element={<Suspense fallback={<CustomSmallLoader/>}><Product /></Suspense>} />
        <Route path="/help" element={<Suspense fallback={<CustomSmallLoader/>}><Help /></Suspense>} />
        <Route path="/services" element={<Suspense fallback={<CustomSmallLoader/>}><RiggleService /></Suspense>} />
        <Route path="/privacy-policy" element={<Suspense fallback={<CustomSmallLoader/>}><PrivacyPolicy /></Suspense>} />
        <Route path="/refund-policy" element={<Suspense fallback={<CustomSmallLoader/>}><RefundPolicy /></Suspense>} />
        <Route path="/shipping-policy" element={<Suspense fallback={<CustomSmallLoader/>}><ShippingPolicy /></Suspense>} />
        <Route path="/terms-and-conditions" element={<Suspense fallback={<CustomSmallLoader/>}><TermsAndConditions /></Suspense>} />
        <Route path="/feedback" element={<Suspense fallback={<CustomSmallLoader/>}><Feedback /></Suspense>} />
        <Route path="/login" element={<Suspense fallback={<CustomSmallLoader/>}><LoginPage /></Suspense>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
